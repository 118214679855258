import {
  Button,
  DateTimeRangeInput,
  Dropdown,
  StyledNumberInput,
  StyledDropdown,
  StyledAddressInput,
  StyledTimeRangeInput,
  StyledDateTimeRangeInput,
  StyledTimeRangeInputPicker,
  StyledDateInput,
  StyledDatePicker,
} from '@monbanquet/crumble'
import React, { useContext, useState, useRef } from 'react'
import styled from 'styled-components'
import { EventContext } from './context/EventContext'
import { formatPrice } from '../util/format'
import { breakpoints, colors } from '../theme'
import iconInfo from '../assets/icons/info.svg'
import { useFocus } from '../hooks/useFocus'
import { ModalContext } from '../components/context/ModalContext'
import AddressField from './AddressField'
import DrawerArrow from '../assets/drawer-arrow.svg'
import NumberInput from '@monbanquet/crumble/src/components/NumberInput/NumberInput'
import { TextInput } from '@monbanquet/crumble/src/index'
import { excludeDates } from '../util/date'

const toOptions = options =>
  options.map(p => ({
    label: `${p} convives`,
    value: p,
  }))

const MenuForm = ({
  menu,
  nbGuestOptions,
  onNbGuestsChange,
  onAddressSelected,
  onChooseMenu,
  ...props
}) => {
  const {
    state: { nbGuests, address, startDateTime, category },
    dispatch,
  } = useContext(EventContext)

  const { dispatch: dispatchModal } = useContext(ModalContext)

  const [wrongAddress, setWrongAddress] = useState(false)
  const [wrongDateTime, setWrongDateTime] = useState(false)

  const addressInputRef = useFocus({ from: breakpoints.tablet })

  const dateInputRef = useRef(null)

  return (
    <StyledMenuForm {...props}>
      <p className="title">
        Commandez
        <img src={DrawerArrow} alt="open" />
      </p>
      <div className="prices">
        <div className="total">
          <p>
            {category == 'lunchbox'
              ? formatPrice(menu.sellingPriceExcludingTax * nbGuests || 0)
              : formatPrice(menu.sellingPriceExcludingTax || 0)}
            <span>(HT)</span>
          </p>
          <p>Hors frais de livraison</p>
        </div>
        <div className="per-pers">
          <p>Soit {formatPrice(menu.sellingPricePerPerson || 0)}</p>
          <span className="price-suffix"> par personne</span>
        </div>
      </div>
      {category == 'lunchbox' ? (
        <>
          <label className="guest-label">
            {' '}
            {/* TODO: remove this label when the navbar dropdown is fixed */}
            Nombre de convives
          </label>
          <NumberInput
            className="nb-people-input"
            label="Nombre de convives"
            value={nbGuests}
            onChange={v => {
              const numV = parseInt(v)
              dispatch({
                type: 'UPDATE_GUESTS',
                payload: { nbGuests: numV },
                analytics: { label: 'From_MenuForm' },
              })
              onNbGuestsChange(numV)
            }}
          />
        </>
      ) : (
        <Dropdown
          label="Nombre de convives"
          value={nbGuests}
          items={toOptions(nbGuestOptions)}
          onChange={v => {
            const numV = parseInt(v)
            dispatch({
              type: 'UPDATE_GUESTS',
              payload: { nbGuests: numV },
              analytics: { label: 'From_MenuForm' },
            })
            onNbGuestsChange(numV)
          }}
        />
      )}
      <AddressField
        ref={addressInputRef}
        label="Adresse de Livraison"
        className={`${wrongAddress ? 'wrong' : ''}`}
        onAddressSelected={v => {
          // Why the f**** startDateTime is null here?
          !startDateTime && dateInputRef.current.showPicker(true)
          onAddressSelected(v)
        }}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
      />
      <DateTimeRangeInput
        ref={dateInputRef}
        className={`delivery-datetime ${wrongDateTime ? 'wrong' : ''}`}
        label="Créneau de Livraison"
        value={startDateTime}
        excludeDates={excludeDates()}
        onSelected={(startDt, endDt) =>
          dispatch({
            type: 'UPDATE_DELIVERY_DATETIME',
            payload: { startDateTime: startDt, endDateTime: endDt },
            analytics: { label: 'From_MenuForm' },
          })
        }
        locale="fr"
        dateHint={
          <p className="date-hint">
            <img src={iconInfo} alt="info" />
            {`Vous pouvez commander jusqu'à 48h avant votre événement`}
          </p>
        }
      />

      <Button
        onClick={() => {
          if (!address) {
            setWrongAddress(true)
            setTimeout(() => setWrongAddress(false), 800)
          } else if (!startDateTime) {
            setWrongDateTime(true)
            setTimeout(() => setWrongDateTime(false), 800)
          } else {
            onChooseMenu(menu)
          }
        }}
        className="choose-btn"
      >
        Choisir ce menu
      </Button>
      <div className="custom-menu">
        <p>Vous souhaitez un menu sur-mesure pour votre événement ?</p>
        <p className="hint">
          Avec plus de 50 artisans partenaires, les possibilités sont
          infinies&nbsp;!
        </p>
        <Button
          className="btn-link"
          onClick={() =>
            dispatchModal({
              type: 'OPEN',
              analytics: { label: 'From_MenuPage' },
            })
          }
        >
          Obtenir un devis
        </Button>
      </div>
    </StyledMenuForm>
  )
}

const StyledMenuForm = styled.div`
  z-index: 1;
  max-width: 450px;
  padding: 0 20px 20px;

  .title {
    font-family: Bely;
    font-size: 37px;
    color: ${colors.brand.black};
    margin-bottom: 40px;

    img {
      display: none;
    }
  }

  .prices {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    
    .total {
      color: ${colors.brand.normal};
      font-weight: bold;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      position: relative;

      > p {

        &:first-child {
          display: flex;

          span {
            font-weight: normal;
            font-size: 12px;
            margin-left: 5px;
          }
        }

        &:last-child {
          color: hsla(0, 0%, 0%, 0.8);
          font-size: 15px;
          font-weight: normal;
        }
      }
    }

    .per-pers {
      font-size: 15px;
      display: flex;
      flex-direction: column;
      text-align: right;
      
      p {
        color: ${colors.brand.black};
      }

      .price-suffix {
        font-size: 11px;
      }
    }
  }

  .guest-label {
    font-size: 12px;
    padding-left: 30px;
    margin-bottom: 5px;
    color: ${colors.brand.black};
  }
  
  .nb-people-input,
  ${StyledNumberInput},
  ${StyledDropdown} {
    margin-bottom: 46px;

    button {
      padding: 17px 30px;
      height: 54px;
    }

    label {
      display: none;
    }
  }

  ${StyledTimeRangeInput} {
    > div {
      max-height: 300px;
    }
  }

  ${StyledAddressInput},
  ${StyledDateTimeRangeInput} {
    margin-bottom: 46px;

    input {
      padding: 17px 30px;
      height: 54px;

      ::placeholder {
        font-size: 15px;
      }
      :-ms-input-placeholder {
        font-size: 15px;
      }
      ::-webkit-input-placeholder {
        font-size: 15px;
      }
    }

    label {
      left: 30px;
    }

    ${StyledDatePicker} {
      top: -54px;
    }

    ${StyledTimeRangeInput} {
      input {
        padding: 17px 20px;
        font-weight: normal;
      }
    }
  }

  ${StyledDateTimeRangeInput} {
    margin-bottom: 30px;
  }

  .choose-btn {
    width: 100%;
    height: 54px;
  }

  .delivery-datetime {
    ${StyledTimeRangeInput} input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    ${StyledTimeRangeInput}:not(.hidden) + ${StyledDateInput} input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .date-hint {
    display: flex;
    font-size: 0.9rem;
    padding: 5px;
    border-radius: 4px;
    color: #666;
    margin-top: 10px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 7px;
      margin-top: 3px;
    }
  }

  .custom-menu {
    text-align: center;
    width: 100%;
    background: #fff;
    padding: 20px;
    border: 1px solid #eee;
    margin-top: 30px;

    p {
      margin: 10px 0;
      font-weight: 700;
    }

    a {
      color: ${colors.brand.normal};
      text-decoration: underline;
    }
    .hint {
      font-weight: normal;
      color: ${colors.text.light};
    }
  }

  @media (max-height: 810px) and (min-width: ${breakpoints.desktop}px) {
    max-width: 400px;

    .nb-people-input,
    ${StyledNumberInput},
    ${StyledDropdown},
    ${StyledAddressInput},
    ${StyledDateTimeRangeInput} {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 1200px) {
    max-width: 420px;
  }

  @media (max-width: 900px) {
    max-width: 370px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    max-width: 100%;
    
    .title {
      height: 60px;
      padding: 25px 35px;
      font-size: 20px;
      text-align: center;
      margin: 0 -20px 20px -20px;
      background-color: ${colors.secondary.yellow};
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        display: block;
        height: 17px;
      }
    }
    .prices {
      padding: 20px;
      margin-bottom: 15px;

      .total p:first-child {
        font-size: 22px;
      }
    }
    
    .nb-people-input,
    ${StyledNumberInput},
    ${StyledDropdown},
    ${StyledAddressInput},
    ${StyledDateTimeRangeInput} {
      margin-bottom: 30px;

      ${StyledDatePicker} {
        top: unset;
        bottom: 54px;
      }
      ${StyledTimeRangeInput} {
        ${StyledTimeRangeInputPicker} {
          bottom: 0;
          top: unset;
          z-index: 1;
        }
      }
    }
  }

  .wrong label {
    color: ${colors.brand.normal};
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      both;
  }
  @keyframes shake-horizontal {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      transform: translateX(-5px);
    }
    20%,
    40%,
    60% {
      transform: translateX(5px);
    }
    80% {
      transform: translateX(4px);
    }
    90% {
      transform: translateX(-4px);
    }
  }
`

export default MenuForm
export { StyledMenuForm }
